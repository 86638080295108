<template>
  <div class="home">
    <NavHeader />
    <div class="home_two">
      <div
        class="brand"
        @mouseleave="catMouseleave()"
      >
        <div class="B_l">
          <div class="B_l_list">
            <ul class="cat_ul">
              <li
                v-for="(item,index) in Category"
                :key="index"
                class="cat"
              >
                <div
                  class="tit"
                  @mouseover="catMouseover(item.cc)"
                >{{ item.cate_name }} <i class="el-icon-arrow-right"></i></div>

                <div class="usually">
                  <div
                    class="item"
                    v-for='(u,i) in usually[index]'
                    @click="
                         toSort({id:u.id1,cate_name:u.name1},{id:u.id2,cate_name:u.name2})
                        "
                    :key='i'
                  >{{u.name2}} </div>

                </div>
              </li>
              <div
                class="catList"
                v-if="catlUL"
              >
                <ul @mouseleave="catMouseleave()">
                  <li
                    v-for="(item1, index1) in catmouList"
                    :key="index1"
                  >
                    <div
                      class="title"
                      @click="
                         toSort(item1,'')
                        "
                    >{{ item1.cate_name }}</div>
                    <dl>
                      <dd
                        @click="
                         toSort(item1,item2)
                        "
                        v-for="(item2, index2) in item1.cc"
                        :key="index1+index2"
                      >
                        {{ item2.cate_name }}
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </ul>

            <el-row class="table">
              <el-col :span="24">
                <p> </p>
                <p>红外测温设备</p>
                <p> </p>
              </el-col>
              <el-col :span="12">
                <img
                  src="@/assets/images/pic01.gif"
                  @click="
                         toSort({id: 119, cate_name: '电子测量仪器'},{id: 162, cate_name: '热像仪', })
                        "
                >

                <span>热成像仪</span>

              </el-col>
              <el-col :span="12">

                <img
                  src="@/assets/images/pic02.gif"
                  @click="
                                           toSort({id: 119, cate_name: '电子测量仪器'},{id: 161, cate_name: '测温仪'})

                         "
                >

                <span>测温仪</span>
              </el-col>

            </el-row>
          </div>
        </div>
        <div class="B_c">
          <!-- position_id (9) -->
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                style="width:760px"
                v-for="(item, index) in banner"
                :key="index"
              >
                <a
                  :href="item.ad_link"
                  target="_blank"
                > <img
                    style="dispaly:inline-block"
                    :src="baseUrl + (item.get_files || {}).files_path"
                  /></a>
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </div>
        <div class="B_r">
          <div class="check">

            <div class="sin">
              <div class="sin-top">
                <div class="sin-img">

                  <img
                    v-if="!member.id||!member.avatar"
                    src="../../assets/images/tx.jpg"
                    alt=""
                  />

                  <img
                    v-else
                    :src="baseUrl+member.avatar"
                    alt=""
                  />

                  <div
                    class="sin-h"
                    v-if="member.id"
                  >
                    <p>Hi!{{ member.name }}<br />欢迎来到仪商城。</p>
                    <el-row>
                      <el-button
                        class="button"
                        style="width:80%"
                        @click="$router.push({ path: '/member-index' })"
                      >进入会员中心</el-button>
                    </el-row>

                  </div>

                  <div
                    class="sin-h"
                    v-else
                  >
                    <p>未登录</p>
                    <el-row>
                      <el-col :span="12">
                        <el-button
                          type="primary"
                          @click="$router.push('/login')"
                        >登 录 </el-button>
                      </el-col>
                      <el-col :span="12">
                        <el-button
                          type="info"
                          @click="$router.push('/register')"
                        >注 册 </el-button>
                      </el-col>
                    </el-row>
                  </div>
                </div>

              </div>

              <ul class="sin-list ">
                <li
                  v-if=' this.member.type == 2'
                  class="hover"
                  @click="toMyshop"
                ><i class="el-icon-s-shop"></i>
                  <template v-if='!this.member.shopId '>我要开店</template>
                  <template v-if='this.member.shopId'>我的店铺</template>

                </li>

                <li
                  v-else
                  class="hover"
                  @click="toMy"
                ><i class="el-icon-s-shop"></i>

                  我的订单

                </li>

                <li
                  class="hover"
                  @click="$router.push('/member-seek')"
                ><i class="el-icon-edit-outline"> </i>我要询价</li>
                <li
                  class="hover"
                  @click="getSign()"
                ><i class="el-icon-s-claim"> </i>每日签到</li>

                <li class="hover"><i class="el-icon-menu"></i>帮助中心</li>

              </ul>
            </div>
          </div>
          <p class="notice">
            <span>仪商公告</span>

          </p>
          <HomeNotice />
          <img
            class="banner04"
            v-for='(item,index) in banner05'
            :key='index'
            :src="$config.baseUrl+item.get_files.files_path"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="content_container">
      <!-- @click="$router.push({path:'/activity',query:{id:76}})" -->
      <!-- <div
        class="banner02"
        style="margin-botoom: 20px"
      >
        <img
          v-for='(item,index) in banner04'
          :key='index'
          :src="$config.baseUrl+item.get_files.files_path"
          alt=""
          class="banner03"
        />
      </div> -->

      <div class="ms">

        <!-- @click="$router.push({path:'/sortProduct',query:{goodslabel:3}   })" -->
        <div class="tit">
          <i class="el-icon-video-play"></i>

        </div>
        <ul>
          <li
            v-for="(good, gIndex) in recomendList"
            :key="gIndex"
            class="item"
          >

            <div class="item-img-warper">
              <img
                class="item-img"
                :src="baseUrl + (good.get_pic||{}).files_path"
                @click="$util.toDetail(good.get_shop.shop_type, good.goods_id )"
              />
            </div>
            <div class="item-info">
              <p
                class="item-name"
                @click="$util.toDetail(good.get_shop.shop_type, good.goods_id )"
              >
                {{ good.goods_name }}
              </p>

              <div
                class="item-price"
                v-if="good.isprice == 2"
              >
                <span>￥{{ parseFloat(good.min_price).toFixed(2) }}</span>
                -
                <span>￥{{ parseFloat(good.max_price).toFixed(2) }}</span>
              </div>

              <div
                class="item-price"
                v-if="good.isonly == 1 && good.isprice == 1"
              >

                <span>￥{{
                        parseFloat(good.sales_price/ 100).toFixed(2) 
                      }}</span>
              </div>
              <div
                class="item-price"
                v-if="good.isonly == 2 && good.isprice == 1"
              >
                <span>￥{{ good.spec_min_price}}
                  起</span>
              </div>

            </div>
          </li>
        </ul>
      </div>

      <div class="c-box">
        <div class="brandList b">
          <!-- @click="$router.push('/brands')" -->
          <h1><b>推荐品牌 <i class="el-icon-video-play"></i></b> <span>品质可靠 经得考验</span> </h1>
          <div class="cont">

            <ul>
              <li
                v-for="(item, index) in Brand_List"
                :key="index"
              >
                <el-image
                  class="img"
                  @click="toBrand(item.id)"
                  :src="baseUrl + (item.files || {}).files_path"
                  lazy
                />

              </li>
            </ul>
          </div>
        </div>
        <div class="brandList b">
          <!-- @click="$router.push({path:'/sortProduct',query:{sale_num_pr:'asc'}   })" -->
          <h1><b>店铺推荐 <i class="el-icon-video-play"></i></b> <span> 诚信经营 值得信赖</span></h1>
          <div class="cont">

            <ul>
              <li
                v-for="(item, index) in recomendshop"
                :key="index"
                @click="toShop(item.shop_id )"
              >

                <el-image
                  class="img"
                  :src="baseUrl +( item.files[0]||{} ).files_path"
                  lazy
                />
                <!-- <dl>
                  <dt>{{ item.shop_name }}</dt>
                

                </dl> -->

              </li>
            </ul>
          </div>

        </div>

      </div>

      <!-- <div
        class="banner02"
        style="margin-botoom: 20px"
      >

        <el-image
          v-for='(item,index) in banner03'
          :key='index'
          :src="$config.baseUrl+item.get_files.files_path"
          lazy
        />
      </div> -->
      <!-- 
      <div
        class="getCategory"
        v-for="item in goodsList"
        :key='item.id'
      >
        <h1> {{item.name}}</h1>
        <div class="cont">

          <ul class="second">
            <li
              v-for="good in item.goods"
              :key="good.goods_id"
              class="item"
            >
              <el-image
                class="item-img"
                :src="$config.baseUrl +  (good || {}).files_path"
                @click="$util.toDetail(good.get_shop.shop_type, good.goods_id )"
                lazy
              />

              <div class="item-info">
                <p
                  class="item-name"
                  @click="$util.toDetail(good.get_shop.shop_type, good.goods_id )"
                >
                  {{ good.goods_name }}
                </p>

                <div
                  class="item-price"
                  v-if="good.isprice == 2"
                >
                  <span>￥{{ parseFloat(good.min_price).toFixed(2) }}</span>
                  -
                  <span>￥{{ parseFloat(good.max_price).toFixed(2) }}</span>
                </div>

                <div
                  class="item-price"
                  v-if="good.isonly == 1 && good.isprice == 1"
                >

                  <span>￥{{
                        parseFloat(good.sales_price/ 100).toFixed(2) 
                      }}</span>
                </div>
                <div
                  class="item-price"
                  v-if="good.isonly == 2 && good.isprice == 1"
                >
                  <span>￥{{ good.spec_min_price}}
                    起</span>
                </div>
              </div>
            </li>

          </ul>
        </div>
      </div> -->
      <div
        class="floorBanner"
        
      >
        <img
         @click="adLink(item.ad_link)"
          v-for='(item,index) in fBanner1'
          :key='index'
          :src="$config.baseUrl+item.get_files.files_path"
          alt=""
          class="hover"
        />
      </div>
      <div class="getCategory">
        <h1>电子测量仪器</h1>
        <div class="cont">

          <ul class="second">
            <li
              v-for="good in goodsList1"
              :key='good.id'
              class="item"
            >
              <el-image
                class="item-img"
                :src="$config.baseUrl +  (good || {}).files_path"
                @click="$util.toDetail(good.get_shop.shop_type, good.goods_id )"
                lazy
              />

              <div class="item-info">
                <p
                  class="item-name"
                  @click="$util.toDetail(good.get_shop.shop_type, good.goods_id )"
                >
                  {{ good.goods_name }}
                </p>

                <div
                  class="item-price"
                  v-if="good.isprice == 2"
                >
                  <span>￥{{ parseFloat(good.min_price).toFixed(2) }}</span>
                  -
                  <span>￥{{ parseFloat(good.max_price).toFixed(2) }}</span>
                </div>

                <div
                  class="item-price"
                  v-if="good.isonly == 1 && good.isprice == 1"
                >

                  <span>￥{{
                        parseFloat(good.sales_price/ 100).toFixed(2) 
                      }}</span>
                </div>
                <div
                  class="item-price"
                  v-if="good.isonly == 2 && good.isprice == 1"
                >
                  <span>￥{{ good.spec_min_price}}
                    起</span>
                </div>
              </div>
            </li>

          </ul>
        </div>
      </div>

      <div
        class="floorBanner"
          v-if="fBanner2.length"
      >
      
        <el-image
          v-for='(item,index) in fBanner2'
          :key='index'
          :src="$config.baseUrl+item.get_files.files_path"
          alt=""
          lazy
        />
      </div>

      <div class="getCategory">
        <h1>电力电工仪表</h1>
        <div class="cont">

          <ul class="second">
            <li
              v-for="good in goodsList2"
              :key='good.id'
              class="item"
            >
              <el-image
                class="item-img"
                :src="$config.baseUrl +  (good || {}).files_path"
                @click="$util.toDetail(good.get_shop.shop_type, good.goods_id )"
                lazy
              />

              <div class="item-info">
                <p
                  class="item-name"
                  @click="$util.toDetail(good.get_shop.shop_type, good.goods_id )"
                >
                  {{ good.goods_name }}
                </p>

                <div
                  class="item-price"
                  v-if="good.isprice == 2"
                >
                  <span>￥{{ parseFloat(good.min_price).toFixed(2) }}</span>
                  -
                  <span>￥{{ parseFloat(good.max_price).toFixed(2) }}</span>
                </div>

                <div
                  class="item-price"
                  v-if="good.isonly == 1 && good.isprice == 1"
                >

                  <span>￥{{
                        parseFloat(good.sales_price/ 100).toFixed(2) 
                      }}</span>
                </div>
                <div
                  class="item-price"
                  v-if="good.isonly == 2 && good.isprice == 1"
                >
                  <span>￥{{ good.spec_min_price}}
                    起</span>
                </div>
              </div>
            </li>

          </ul>
        </div>
      </div>
      <div
        class="floorBanner"
          v-if="fBanner3.length"
      >
        <el-image
          v-for='(item,index) in fBanner3'
          :key='index'
          :src="$config.baseUrl+item.get_files.files_path"
          alt=""
          lazy
        />
      </div>
      <div class="getCategory">
        <h1>自动化仪表与控制系统</h1>
        <div class="cont">

          <ul class="second">
            <li
              v-for="good in goodsList3"
              :key='good.id'
              class="item"
            >
              <el-image
                class="item-img"
                :src="$config.baseUrl +  (good || {}).files_path"
                @click="$util.toDetail(good.get_shop.shop_type, good.goods_id )"
                lazy
              />

              <div class="item-info">
                <p
                  class="item-name"
                  @click="$util.toDetail(good.get_shop.shop_type, good.goods_id )"
                >
                  {{ good.goods_name }}
                </p>

                <div
                  class="item-price"
                  v-if="good.isprice == 2"
                >
                  <span>￥{{ parseFloat(good.min_price).toFixed(2) }}</span>
                  -
                  <span>￥{{ parseFloat(good.max_price).toFixed(2) }}</span>
                </div>

                <div
                  class="item-price"
                  v-if="good.isonly == 1 && good.isprice == 1"
                >

                  <span>￥{{
                        parseFloat(good.sales_price/ 100).toFixed(2) 
                      }}</span>
                </div>
                <div
                  class="item-price"
                  v-if="good.isonly == 2 && good.isprice == 1"
                >
                  <span>￥{{ good.spec_min_price}}
                    起</span>
                </div>
              </div>
            </li>

          </ul>
        </div>
      </div>
      <div
        class="floorBanner"
           v-if="fBanner4.length"
       
      >
       <el-image
          v-for='(item,index) in fBanner4'
          :key='index'
          :src="$config.baseUrl+item.get_files.files_path"
          alt=""
          lazy
        />
      </div>
      <div class="getCategory">
        <h1>传感器及其它元器件</h1>
        <div class="cont">

          <ul class="second">
            <li
              v-for="good in goodsList4"
              :key='good.id'
              class="item"
            >
              <el-image
                class="item-img"
                :src="$config.baseUrl +  (good || {}).files_path"
                @click="$util.toDetail(good.get_shop.shop_type, good.goods_id )"
                lazy
              />

              <div class="item-info">
                <p
                  class="item-name"
                  @click="$util.toDetail(good.get_shop.shop_type, good.goods_id )"
                >
                  {{ good.goods_name }}
                </p>

                <div
                  class="item-price"
                  v-if="good.isprice == 2"
                >
                  <span>￥{{ parseFloat(good.min_price).toFixed(2) }}</span>
                  -
                  <span>￥{{ parseFloat(good.max_price).toFixed(2) }}</span>
                </div>

                <div
                  class="item-price"
                  v-if="good.isonly == 1 && good.isprice == 1"
                >

                  <span>￥{{
                        parseFloat(good.sales_price/ 100).toFixed(2) 
                      }}</span>
                </div>
                <div
                  class="item-price"
                  v-if="good.isonly == 2 && good.isprice == 1"
                >
                  <span>￥{{ good.spec_min_price}}
                    起</span>
                </div>
              </div>
            </li>

          </ul>
        </div>
      </div>

      <div
        class="floorBanner"
        v-if="fBanner5.length"
      >
        <el-image
          v-for='(item,index) in fBanner5'
          :key='index'
          :src="$config.baseUrl+item.get_files.files_path"
          alt=""
          lazy
        />
      </div>
      <div class="getCategory">
        <h1>科学仪器</h1>
        <div class="cont">

          <ul class="second">
            <li
              v-for="good in goodsList5"
              :key='good.id'
              class="item"
            >
              <el-image
                class="item-img"
                :src="$config.baseUrl +  (good || {}).files_path"
                @click="$util.toDetail(good.get_shop.shop_type, good.goods_id )"
                lazy
              />

              <div class="item-info">
                <p
                  class="item-name"
                  @click="$util.toDetail(good.get_shop.shop_type, good.goods_id )"
                >
                  {{ good.goods_name }}
                </p>

                <div
                  class="item-price"
                  v-if="good.isprice == 2"
                >
                  <span>￥{{ parseFloat(good.min_price).toFixed(2) }}</span>
                  -
                  <span>￥{{ parseFloat(good.max_price).toFixed(2) }}</span>
                </div>

                <div
                  class="item-price"
                  v-if="good.isonly == 1 && good.isprice == 1"
                >

                  <span>￥{{
                        parseFloat(good.sales_price/ 100).toFixed(2) 
                      }}</span>
                </div>
                <div
                  class="item-price"
                  v-if="good.isonly == 2 && good.isprice == 1"
                >
                  <span>￥{{ good.spec_min_price}}
                    起</span>
                </div>
              </div>
            </li>

          </ul>
        </div>
      </div>

      <div class="getCategory">
        <h1>专用仪器</h1>
        <div class="cont">

          <ul class="second">
            <li
              v-for="good in goodsList5"
              :key='good.id'
              class="item"
            >
              <el-image
                class="item-img"
                :src="$config.baseUrl +  (good || {}).files_path"
                @click="$util.toDetail(good.get_shop.shop_type, good.goods_id )"
                lazy
              />

              <div class="item-info">
                <p
                  class="item-name"
                  @click="$util.toDetail(good.get_shop.shop_type, good.goods_id )"
                >
                  {{ good.goods_name }}
                </p>

                <div
                  class="item-price"
                  v-if="good.isprice == 2"
                >
                  <span>￥{{ parseFloat(good.min_price).toFixed(2) }}</span>
                  -
                  <span>￥{{ parseFloat(good.max_price).toFixed(2) }}</span>
                </div>

                <div
                  class="item-price"
                  v-if="good.isonly == 1 && good.isprice == 1"
                >

                  <span>￥{{
                        parseFloat(good.sales_price/ 100).toFixed(2) 
                      }}</span>
                </div>
                <div
                  class="item-price"
                  v-if="good.isonly == 2 && good.isprice == 1"
                >
                  <span>￥{{ good.spec_min_price}}
                    起</span>
                </div>
              </div>
            </li>

          </ul>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import Swiper from "swiper";
import HomeNotice from "../../components/home-notice";
import NavHeader from "../../components/layout/nav-header";
import "swiper/dist/css/swiper.min.css";

export default {
  data() {
    return {
      usually: [
        [
          { name1: "电子测量仪器", id1: 121, name2: "示波器", id2: 214 },
          { name1: "电子测量仪器", id1: 121, name2: "热像仪", id2: 162 }
        ],
        [
          { name1: "电力电工仪表", id1: 220, name2: "万用表", id2: 229 },
          { name1: "电力电工仪表", id1: 220, name2: "钳形表", id2: 230 }
        ],
        [
          { name1: "自动化仪表", id1: 168, name2: "压力仪表", id2: 224 },
          { name1: "自动化仪表", id1: 168, name2: "流量仪表", id2: 209 }
        ]
      ],
      // usually: [
      //   ["示波器", " 热像仪"],
      //   ["万用表", "钳形表"],
      //   ["压力仪表", "流量仪表"]
      //   // ["温度传感器 ", "流量传感器"],
      //   // ["光谱仪 ", "频谱分析仪"],
      //   // ["有毒有害气体检测", "温湿度记录仪"]
      // ],
      banner: [],
      banner02: [],
      banner03: [],
      banner04: [],
      banner05: [],
      fBanner1: [],
      fBanner2: [],
      fBanner3: [],
      fBanner4: [],
      fBanner5: [],
      recomendshop1: [],
      recomendList1: {},
      recomendList2: [],
      recomendList: [],
      Category: [],
      goodsList: [],
      goodsList1: [],
      goodsList2: [],
      goodsList3: [],
      goodsList4: [],
      goodsList5: [],
      goodsList6: [],
      List: [],
      account: "",
      sinIn: true,
      sele: false,
      date: new Date(),
      iscur: "",

      baseUrl: this.$config.baseUrl,
      notice: [
        {
          name: "仪商公告"
        }
      ],
      active: "",

      isTop: true,
      osTop: "",

      num: "",
      carData: "",
      Brand_List1: {},
      Brand_List: [],
      boxshow: false,
      Scboxshow: false,
      sinInTotal: 0,
      sinInCount: 0,
      sinIntime: "",
      floor: ["品牌专区", "推荐热卖", "为您推荐"],
      catmouList: [],
      catlUL: false,
      catid: "",
      advertising: "",
      NumData: "",
      lists: [],
      list_one: [],
      recomendshop: []
    };
  },
  components: {
    HomeNotice,
    NavHeader
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); //在Vue实例销毁前，清除我们的定时器
    }
    window.removeEventListener("scroll", this.handleScroll);
    // console.log('haihao y')
  },
  mounted() {
    // this.getCategory();
    this.renderBanner();

    var that = this;
    this.timer = setInterval(() => {
      that.date = new Date(); //修改数据date
    }, 1000);
    //监听滚动事件
    window.addEventListener("scroll", this.handleScroll);
    // console.log('')
  },
  created() {
    this.$get("/home/goods").then(res => {
      let arr = res.items.data;

      this.list_one = arr;
    });

    //广告位列表
    this.$get("home/ad").then(res => {
      console.log("gg", res);
    });
    //产品

    //产品分类列表

    //产品分类列表
    this.$get("/home/goodsCategory").then(res => {
      this.Category = res;
    });

    this.getBrand();
    this.banner_2();
    this.banner_3();
    this.banner_4();
    this.banner_5();

    this.getRecomendList();
    this.getRecomendshop();
    this.getGoods1();
    this.getGoods2();
    this.getGoods3();
    this.getGoods4();
    this.getGoods5();
    this.getGoods6();
    this.floorBanner1();
    this.floorBanner2();
    this.floorBanner3();
    this.floorBanner4();
    this.floorBanner5();
  },
  computed: {
    member() {
      return this.$store.state.member;
    },
    shop() {
      return this.$store.state.shop;
    }
  },
  methods: {
    adLink(link){

    
      
      window.open(link, "_blank");

    },

    toMyshop() {
      if (this.member.id && this.member.type == 2 && this.member.shopId) {
        if (this.shop.status == 0) {
          this.$alert("您的店铺还在审核中，请耐心等待。");
        }
        if (this.shop.status == 3) {
          this.$alert("很抱歉，您的店铺入驻申请未通过，请重新提交申请", {
            confirmButtonText: "确定"
          }).then(() => {
            let routeData = this.$router.resolve({
              path: "/shopRegister"
            });

            window.open(routeData.href, "_blank");
          });
        }
        if (this.shop.status == 2) {
          window.open(
            "https://www.yishangm.com/#/shop?id=" + this.member.shopId,
            "_blank"
          );
        }
      } else if (
        this.member.id &&
        this.member.type == 2 &&
        !this.member.shopId
      ) {
        let routeData = this.$router.resolve({
          path: "/shopRegister"
        });

        window.open(routeData.href, "_blank");
      } else if (!this.member.id) {
        this.$alert("请先登录");
      }
    },
    toMy() {
      if (!this.member.id) {
        this.$alert("请先登录", {
          confirmButtonText: "确定"
        }).then(() => {
          let routeData = this.$router.resolve({
            path: "/login"
          });

          window.open(routeData.href, "_blank");
        });
      } else {
        let routeData = this.$router.resolve({
          path: "/member-order"
        });

        window.open(routeData.href, "_blank");
      }
    },
    shopRegister() {
      if (this.member.id) {
        if (this.member.type == 2) {
          const routeData = this.$router.resolve({
            path: "/shopRegister"
          });

          window.open(routeData.href, "_blank");
        } else {
          this.dialogVisible = true;
        }
      } else {
        this.dialogVisible2 = true;
      }
    },
    // 获取分类
    // getCategory() {
    //   this.$get("home/goodsCategory", 1).then(res => {
    //     // console.log("goodsCategory", res);
    //     let data = [];
    //     res.forEach(item => {
    //       if (!item.display) return;
    //       data.push({
    //         id: item.id,
    //         name: item.cate_name,
    //         image: item.image,
    //         goods: []
    //       });
    //     });
    //     this.goodsList = data;
    //     this.getGoods();
    //   });
    // },
    // 获取商品
    getGoods1() {
      this.$get("home/goods", {
        pcatid: 119,
        goods_label: 3
      }).then(res => {
        this.goodsList1 = res.items.data.filter((item, index) => index < 18);
        console.log(" this.goodsList1 ", this.goodsList1);
      });
    },

    getGoods2() {
      this.$get("home/goods", {
        pcatid: 128,
        goods_label: 3
      }).then(res => {
        this.goodsList2 = res.items.data.filter((item, index) => index < 18);
      });
    },
    getGoods3() {
      this.$get("home/goods", {
        pcatid: 131,
        goods_label: 3
      }).then(res => {
        this.goodsList3 = res.items.data.filter((item, index) => index < 18);
      });
    },
    getGoods4() {
      this.$get("home/goods", {
        pcatid: 133,
        goods_label: 3
      }).then(res => {
        this.goodsList4 = res.items.data.filter((item, index) => index < 18);
      });
    },
    getGoods5() {
      this.$get("home/goods", {
        pcatid: 138,
        goods_label: 3
      }).then(res => {
        this.goodsList5 = res.items.data.filter((item, index) => index < 18);
      });
    },
    getGoods6() {
      this.$get("home/goods", {
        pcatid: 138,
        goods_label: 3
      }).then(res => {
        this.goodsList6 = res.items.data.filter((item, index) => index < 18);
      });
    },

    // // 获取商品
    // getGoods() {
    //   console.log("itemname", this.goodsList);
    //   this.goodsList.forEach((item, index) => {
    //     this.$get("home/goods", {
    //       pcatid: item.id,
    //       goods_label: 3
    //     }).then(res => {
    //       let data = [];
    //       res.items.data.forEach((o, i) => {
    //         data.push(o);
    //       });
    //       data = data.filter((item, index) => index < 18);
    //       item.goods = data;
    //     });
    //   });
    // },
    toBrand(id) {
      let routerJump = this.$router.resolve({
        path: "/searchProduct",
        query: { brand_id: id }
      });
      window.open(routerJump.href, "_blank");
    },
    toShop(id) {
      let routerJump = this.$router.resolve({
        path: "/shop",
        query: { id: id }
      });
      window.open(routerJump.href, "_blank");
    },
    toSort(item1, item2) {
      if (!item2) {
        let routerJump = this.$router.resolve({
          path: "/sortProduct",
          query: { catid: item1.id, sort: item1.cate_name }
        });
        window.open(routerJump.href, "_blank");
      } else {
        let routerJump = this.$router.resolve({
          path: "/sortProduct",
          query: {
            catid: item2.id,
            sort: item2.cate_name,
            catid0: item1.id,
            sort0: item1.cate_name
          }
        });
        window.open(routerJump.href, "_blank");
      }
      // location.reload();
    },
    //签到
    getSign() {
      if (!this.member.id) {
        this.$message("请先登录");
        return;
      }
      if (!(this.sign == "今日已签到")) {
        this.$post("home/sign", { member_id: this.member.id }).then(res => {
          console.log(res);

          this.sign = "今日已签到";
          this.$message.success("签到成功，积分+20");
        });
      } else {
        this.$message.warning("您今日已签到");
      }
    },

    // 渲染 swiper banner 图
    renderBanner() {
      this.$get("home/ad", { pid: 9 }).then(res => {
        this.banner = res;

        this.$nextTick(() => {
          new Swiper(".swiper-container", {
            pagination: {
              el: ".swiper-pagination",
              clickable: true
            },
            speed: 1000,
            loop: true,
            observer: true,
            observeParents: true,
            autoplay: {
              disableOnInteraction: false, // 用户操作swiper之后，是否禁止autoplay
              delay: 2000 // 自动切换的时间间隔（单位ms），
            }
          });
        });
      });
    },

    banner_2() {
      this.$get("home/ad", { pid: 302 }).then(res => {
        this.banner02 = res;
      });
    },
    banner_4() {
      this.$get("home/ad", { pid: 300 }).then(res => {
        this.banner04 = res;
      });
    },
    banner_5() {
      this.$get("home/ad", { pid: 538 }).then(res => {
        this.banner05 = res || [];
      });
    },
    banner_3() {
      this.$get("home/ad", { pid: 301 }).then(res => {
        this.banner03 = res;
      });
    },

    floorBanner1() {
      this.$get("home/ad", { pid: 543 }).then(res => {
        this.fBanner1 = res;
      });
    },
    floorBanner2() {
      this.$get("home/ad", { pid: 544 }).then(res => {
        this.fBanner2 = res;
      });
    },
      floorBanner3() {
      this.$get("home/ad", { pid: 545 }).then(res => {
        this.fBanner3 = res;
      });
    },
     floorBanner4() {
      this.$get("home/ad", { pid: 546 }).then(res => {
        this.fBanner4 = res;
      });
    },
     floorBanner5() {
      this.$get("home/ad", { pid: 547 }).then(res => {
        this.fBanner5 = res;
      });
    },

    //获取推荐店铺
    getRecomendshop() {
      this.$get("home/shop", { recommend: 1, page: 1, limit: 6 }).then(res => {
        this.recomendshop = res.items.data;

        // this.recomendshop1 = arr[0];
        // this.recomendshop = res.items.data.splice(1);
      });
    },
    //获取热卖商品
    getRecomendList() {
      this.$get("home/goods", { goods_label: 2 }).then(res => {
        this.recomendList = res.items.data;
      });
    },

    getBrand() {
      this.$post("home/brand/recommend/", {
        is_recommend: "1",
        page: 1,
        limit: 9
      }).then(res => {
        this.Brand_List = res.items.data;
        // this.Brand_List1 = arr[0];
        // this.Brand_List = res.items.data.splice(1);
      });
    },

    open(pd) {
      let path = pd.ad_link;
      // console.log(path)
      window.open("https://" + path, "_blank").location;
      //  window.open.href = 'http://www.baidu.com'
    },

    catMouseover(item) {
      // console.log(item)
      this.catmouList = item;
      // console.log(this.catmouList)
      this.catlUL = true;
    },
    catMouseleave() {
      this.catlUL = false;
    },

    handleScroll() {
      var scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      // var offsetTop = document.querySelector('.List').offsetTop
      if (scrollTop > 800) {
        this.sele = true;
        this.boxshow = true;
      } else {
        this.sele = false;
        this.boxshow = false;
      }
    },

    selected(index) {
      this.iscur = index;
      this.active = index;
      if (this.active == 0) {
        // $("body,html").animate(
        //   {
        //     scrollTop: $(".List-zero").offset().top - 100,
        //   },
        //   100
        // );
      } else if (this.active == 1) {
        // $("body,html").animate(
        //   {
        //     scrollTop: $(".List-one").offset().top - 60,
        //   },
        //   100
        // );
      } else if (this.active == 2) {
        // $("body,html").animate(
        //   {
        //     scrollTop: $(".List-two").offset().top - 60,
        //   },
        //   100
        // );
      }
    }
  }
};
</script>
//局部样式
<style lang="scss" scoped>
@import "@/assets/styles/base";
@import "@/assets/styles/mixins";
.hover{cursor: pointer;}
.getCategory {
  margin-top: 10px;
  background: #fff;
  padding: 10px;
  h1 {
    padding-left: 20px;
    padding-top: 10px;
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: bold;
  }

  .cont {
    @include space-between;

    .first {
      width: 250px;
      height: 250px;
    }
    ul::after {
      clear: both;
    }
    ul {
      margin: 0 -10px;
      flex: 1;
      display: flex;
      padding: 10px;
      overflow: hidden;
      background: #fff;
      width: 100%;

      @include row-wrap;
      li {
        width: 180px;
        height: 250px;

        overflow: hidden;
        margin: 0 10px;
        box-sizing: border-box;
        margin-bottom: 20px;
        .item-name {
          text-align: left;
          padding: 5px 0;
          font-size: 14px;
          color: #757575;
          line-height: 18px;
          overflow: hidden;
          height: 43px;
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .item-price {
          width: 100%;
          line-height: 20px;
          text-align: left;
          span {
            font-size: 14px;
            color: red;
            font-weight: bold;
            text-align: left;
          }
        }
        .el-image {
          width: 100%;
          height: 180px;
        }
        img {
          width: 100%;
          height: 180px;
        }
      }
    }
  }
}
.c-box {
  margin-top: 10px;
  width: 100%;
  @include space-between;
  .b {
    width: 49.5%;
    background: #fff;
    height: 250px;
    padding: 20px;
    box-sizing: border-box;
  }
  h1 {
    @include space-between;

    padding-bottom: 20px;
    b {
      font-size: 22px;
      i {
        font-size: 18px;
        color: #e94c15;
      }
    }
    span {
      color: #999;
      font-weight: normal;
      font-size: 14px;
    }
  }

  .brandList {
    margin-bottom: 10px;
    .cont {
      @include space-between;
      .first {
        padding: 10px;
        height: 230px;
        @include align-center;
        @include center;
        text-align: center;
        background: #f8f8f9;
        width: 200px;
        margin-right: 10px;
        box-sizing: border-box;
        img {
          margin-bottom: 10px;
          width: 160px;
          height: 60px;
        }
        dl {
          dt {
            font-size: 14px;
            margin-bottom: 10px;
          }
          dd {
            font-weight: bold;
            font-size: 14px;

            margin-bottom: 10px;
          }
          dd:nth-child(1) {
            color: #999;
            font-weight: normal;
          }
        }
      }
      ul ::after {
        clear: both;
      }
      ul {
        flex: 1;
        li {
          width: 33%;
          float: left;

          margin-bottom: 20px;
          text-align: center;
          .img {
            width: 150px;
            height: 60px;
            margin-right: 10px;
            box-sizing: border-box;
          }
        }
      }
    }
  }

  .advList {
    .cont {
      @include space-between;
      .first {
        padding: 10px;
        height: 230px;
        @include align-center;
        @include center;
        text-align: center;
        background: #f7f4f4;
        width: 170px;
        margin-right: 10px;
        box-sizing: border-box;
        img {
          margin-bottom: 10px;
          width: 100px;
          height: 100px;
        }
        dl {
          dt {
            font-size: 14px;
          }
          dd {
            color: #999;
            margin-top: 10px;
          }
        }
      }
      ul ::after {
        clear: both;
      }
      ul {
        padding: 0 10px;
        flex: 1;
        @include space-between;
        li {
          width: 33%;
          background: #eee;
          padding: 10px;
          text-align: center;
          margin: 0 5px;

          box-sizing: border-box;

          img {
            width: 145px;
            height: 60px;
            border: 3px solid #fff;
            box-sizing: border-box;
          }
          dl {
            flex: 1;
            dt {
              font-size: 15px;
              padding: 10px 0;
            }
          }
        }
      }
    }
  }
}
.ms {
  height: 240px;
  overflow: hidden;
  margin-top: 10px;
  width: 100%;
  @include space-between;
  .tit {
    width: 170px;
    height: 240px;
    background: url("../../assets/images/ms.jpg") no-repeat;
    background-size: 100% 100%;
    text-align: center;
    i {
      margin-top: 180px;
      font-size: 30px;
      color: #fff;
    }
  }
  ul {
    flex: 1;
    display: flex;
    padding: 10px;
    background: #fff;
    flex-wrap: wrap;
    width: 100%;

    .item {
      overflow: hidden;
      background: #fff;
      position: relative;
      margin: 0 10px;
      float: left;
      width: 180px;

      a {
        @include space-between;
      }
      .item-img-warper {
        width: 100%;
        height: 150px;
        box-sizing: border-box;

        .item-img {
          box-sizing: border-box;
          width: 100%;

          height: 100%;
          padding: 10px;
        }
      }
      .item-info {
        @include wrap-between;

        width: 100%;
        .item-name {
          text-align: left;
          padding: 5px 0;
          font-size: 14px;
          color: #757575;
          line-height: 18px;
          overflow: hidden;
          height: 43px;
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .item-price {
          width: 100%;
          line-height: 20px;
          text-align: left;
          span {
            color: red;
            font-size: 18px;
            text-align: left;
          }
        }
      }
    }
  }
}
.floorBanner {
  margin-top: 10px;
   margin-bottom: 10px;
  ::v-deep .el-image {
    width: 16% !important;
    height: 100px;
  }
  display: flex;
  justify-content: space-between;

  img {
    width: 16% !important;
    height: 100px;
  }
}
.banner02 {
  ::v-deep .el-image {
    width: 49.8% !important;
    height: 100px;
  }
  display: flex;
  justify-content: space-between;

  img {
    width: 49.8%;
    height: 100px;
  }
}

.bannerlist {
  ::v-deep .el-image {
    width: 16% !important;
    height: 100px;
    margin: 0 3px;
  }
  display: flex;
  // justify-content: space-between;

  img {
    margin: 0 5px;
    width: 16%;
    height: 100px;
  }
}
.banner03 {
  width: 100%;
  height: 100px;
}
.List-title {
  text-align: center;
  padding-bottom: 10px;
}
.button {
  border-radius: 0;
  background-color: #e94c15;
  border: 2px solid #e94c15;
  color: #fff;
}
.recomend-product {
  b {
    font-weight: bold;
  }
  overflow: hidden;
  margin-bottom: 10px;

  .recomend-product-list::-webkit-scrollbar {
    display: none;
  }
  .recomend-product-list {
    overflow-x: scroll;
    white-space: nowrap;

    margin: 0 -5px;
    margin-bottom: 20px;
    .item {
      display: inline-block;
      width: 290px;
      margin: 0 5px;
      height: 100%;
      position: relative;
      border: none !important;
      background: #fff;
      padding: 10px;
      box-sizing: border-box;
      .title {
        white-space: normal;
        text-align: left;

        font-size: 18px;
        color: #757575;
        line-height: 20px;
        overflow: hidden;
        height: 50px;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .subtitle {
        line-height: 30px;

        color: #fe6925;
        font-size: 16px;
      }
      dl {
        display: block;
        overflow: hidden;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        dt {
          p {
            padding-top: 10px;
            box-sizing: border-box;
            line-height: 30px;
            height: 60px;
            color: #fe6925;
            font-size: 22px;
            text-align: left;
          }
          flex: 1;
          i {
            margin-left: -10px;
            background: #fe6925;
            color: #fff;
            border-radius: 2px;
            width: 60px;
            line-height: 30px;
            height: 30px;
            text-align: center;
            font-size: 14px;
            display: inline-block;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
          }
        }
        dd {
          width: 130px;
          height: 110px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .item-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 24px;
      }
      .item-price {
        display: flex;
        justify-content: space-between;

        line-height: 24px;

        color: red;
      }
      span {
        width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.box {
  width: 100%;
  height: 50px;
  background-color: #e94c15;
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 111111;

  .box_center {
    height: 50px;
    width: 1226px;
    margin: 0 auto;

    // background: yellow;
    // line-height: 50px;
    h1 {
      float: left;
      color: #fff;
      font-size: 22px;
      margin-top: 5px;
    }
  }
}

.mybox-leave-active,
.mybox-enter-active {
  transition: all 0.5s ease;
}

.mybox-leave-active,
.mybox-enter {
  height: 0px !important;
}

.mybox-leave,
.mybox-enter-active {
  height: 50px;
}

.home {
  background: #f5f5f5;
}

.BrandList {
  .List-title {
    h1 {
      height: 35px;
      line-height: 35px;
      font-size: 16px;
      width: 110px;
      background: #f15453;
      color: #fff;
      padding-left: 5px;

      i {
        font-size: 20px;
        margin-right: 5px;
      }
    }
  }

  margin-bottom: 10px;

  ul {
    margin: 0 -5px;
    height: 200px;
    overflow: hidden;
    li {
      width: 230px;
      height: 100px;
      float: left;
      margin: 0 5px;

      margin-bottom: 10px;

      background-color: #fff;

      .brand-img {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: block;
        text-align: center;
        // line-height: 108px;
        background-color: #fff;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .brand-mask {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        background-color: #757575;
        background-color: rgba(0, 0, 0, 0.8);
        transition: all 0.3s ease-out;
        opacity: 0;

        .coupon {
          color: #fff;
          text-align: center;
          line-height: 122px;
          height: 122px;

          span {
            font-size: 12px;
            display: block;
            padding: 0 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        a .enter {
          text-align: center;
          height: 32px;

          span {
            display: inline-block;
            color: #fff;
            width: 63px;
            height: 18px;
            font-size: 12px;
            line-height: 15px;
            background-color: #ff0036;
            border-radius: 9px;
          }
        }
      }

      .brand-mask:hover {
        opacity: 1;
        cursor: pointer;
      }
    }
  }

  // ul::after {
  //   content: "";
  //   width: 230px;
  //   display: block;
  // }
}

// .advList {
//   .List-title {
//     text-align: center;

//     h1 {
//       height: 35px;
//       line-height: 35px;
//       font-size: 16px;
//       width: 110px;
//       background: #f15453;
//       color: #fff;
//       padding-left: 5px;

//       i {
//         font-size: 20px;
//         margin-right: 5px;
//       }
//     }
//   }
//   // height: 120px;
//   // line-height: 120px;
//   margin-bottom: 15px;
//   ul::after {
//     content: "";
//     display: block;
//     clear: both;
//   }
//   ul {
//     li {
//       width: 610px;
//       height: 100px;
//       float: left;
//       border: 1px solid #ccc;

//       p {
//         height: 100px;
//         line-height: 100px;
//         text-align: center;
//         font-size: 20px;
//       }

//       img {
//         width: 100%;
//         height: 100%;
//       }
//     }
//   }
// }

.bom-title {
  margin-top: 15px;
  height: 50px;
  text-align: center;

  span {
    font-size: 18px;
    font-family: "Microsoft YaHei", SimSun, sans-serif,
      "\65b9\6b63\5170\4ead\9ed1";
    font-weight: 100;

    i {
      font-size: 18px;
      color: #ff0036;
      margin: 0px 5px;
    }
  }
}

.over {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.7; //透明度为70%
  filter: alpha(opacity=70);
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #111111;
}

.home_top {
  width: 100%;
  background-color: #fff;
}

.home_two {
  margin-bottom: 10px;
  width: 100%;
  // background-color: #e94c15;
  // background-image: linear-gradient(to right, #7a88ff, #7affaf);

  .brand {
    width: 1226px;
    margin: 0 auto;
  }
}

.com {
  position: relative;
  height: 180px;

  width: 1226px;
  margin: 0 auto;

  .logo_img {
    width: 200px;

    img {
      width: 100%;
    }
  }

  .Search_Goods {
    position: absolute;
    left: 345px;
    top: 70px;
    overflow: hidden;
    width: 615px;
  }

  .Shopping_Cart {
    position: absolute;
    height: auto;
    right: 0px;
    top: 70px;
    cursor: pointer;
    width: 200px;

    .overBox {
      position: absolute;
      right: 0px;
      top: 40px;
      z-index: 3000000;
      width: auto;
      height: 300px;
      overflow: hidden;
    }

    .overcat-leave-active,
    .overcat-enter-active {
      transition: all 0.5s ease;
    }

    .overcat-leave-active,
    .overcat-enter {
      height: 0px !important;
    }

    .overcat-leave,
    .overcat-enter-active {
      max-height: 320px;
    }

    p {
      width: 160px;
      float: right;
      border: 2px solid #e94c15;
      padding: 8px 18px;
      background: #e94c15;

      i {
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        margin-right: 5px;

        span {
          width: 15px;
          height: 15px;
          margin-left: 10px;
          line-height: 15px;
          text-align: center;
          background: #db2726;
          color: #fff;
          border-radius: 50%;
        }
      }

      span {
        color: #fff;
        font-weight: bold;
      }
    }
  }

  .SG_ul {
    :nth-child(n + 2) {
      padding-left: 10px;
    }

    li {
      float: left;
      font-size: 14px;
      padding: 5px 0 0 0;
      color: #a8a8a8;
    }
  }

  .com_ul {
    height: 20px;
    width: 800px;
    float: left;
    margin-left: 20px;
    margin-top: 15px;
    font-weight: bold;
    font-size: 16px;

    :hover {
      color: red;
      cursor: pointer;
    }

    :nth-child(n + 2) {
      margin-left: 40px;
    }

    li {
      float: left;
      font-size: 16px;

      a {
        font-size: 16px;
        color: #222;
      }

      i {
        font-size: 16px;
      }
    }
  }
}

.brand {
  height: 460px;
  @include space-between;

  .B_l {
    background-color: #fff;
    width: 220px;
    z-index: 11;
    float: left;

    // p {
    //   background-color: #000;
    //   height: 36px;
    //   line-height: 40px;

    //   font-size: 14px;
    //   color: #fff;
    //   margin-top: -36px;
    // }

    .B_l_list {
      .catList {
        height: 460px;
        width: 700px;
        background: #fafafa;
        position: absolute;
        left: 220px;

        top: 0;
        .title {
          width: 100%;
          text-align: left;
          font-weight: bold;
          font-size: 14px;
        }
        dl::after {
          display: block;
          content: "";
          clear: both;
        }
        dl {
          width: 100%;

          dd {
            float: left;
            margin-right: 20px;
          }
          dd :hover {
            color: red;
          }
        }
        ul {
          position: absolute;
          left: 0;
          top: 0;
          z-index: 10000000000000;
          background: #fff;
          color: #222;
          height: 459px;
          overflow-y: scroll;
          width: 600px;

          li {
            padding: 10px 10px;
            box-sizing: border-box;

            // margin-left: 15px;
            cursor: pointer;

            line-height: 35px;

            a {
              color: #222;
              font-size: 14px;
            }
          }
        }
      }

      // border:1px solid #fff;

      // background-color:#fff;
      // ul:nth-child(-n + 5) {
      //   border-bottom: 1px solid #ccc;
      // }
      height: 460px;
      background-color: #e94c15;

      .cat_ul {
        // background-color: rgba(0,0,0,.55);

        width: 100%;

        position: relative;
        z-index: 11;
        .tit {
          @include space-between;
          i {
            color: #fff;
            margin-top: 5px;
          }
          color: #fff;
          padding: 7px 10px;
          font-weight: bold;
          font-size: 14px;
        }
        .tit:hover {
          i {
            color: #666;
            margin-top: 5px;
          }
          background: #fff;
          position: relative;
          color: #000;
        }
        .usually {
          box-sizing: border-box;

          margin: 0 10px;
          .item {
            width: 50%;
            float: left;
            color: #fff;
            padding: 5px 0;
            font-size: 12px;
          }
          padding-bottom: 5px;
        }
        .usually::after {
          content: "";
          display: block;
          clear: both;
        }
        .cat {
          // position: relative;
          border-top: 1px solid #fe7c4f;
          width: 100%;
          position: relative;
          color: #fff;
          font-size: 14px;
          cursor: pointer;

          p {
            height: 30px;
            line-height: 30px;
            color: #fff;

            span {
              color: #fff;
            }
          }
        }
      }
      .table {
        width: 100%;
        height: 100px;
        text-align: center;
        img {
          // width: 50px;
          // height: 50px;
          margin-top: 20px;
        }
        span {
          display: block;
          text-align: center;
          color: #fff;
        }
        .el-col:nth-child(2) {
          border-right: 1px dashed #fff;
        }
        .el-col:nth-child(1) {
          line-height: 50px;
          display: flex;
          justify-content: space-between;
          p:nth-child(1) {
            border-bottom: 1px dashed #fff;
            flex: 1;
          }
          p:nth-child(3) {
            border-bottom: 1px dashed #fff;
            flex: 1;
          }
          p {
            color: #fff;
            margin: 0 5px;
            line-height: 40px;
            height: 20px;
            margin-top: 0;
          }
        }
      }
    }
  }

  .B_c {
    height: 460px;
    width: 760px;
    // background-color: black;
    float: left;
    position: relative;

    img {
      height: 460px;
      width: 100%;
      cursor: pointer;
    }

    .swiper-pagination {
      position: absolute;
      left: 0px;
      bottom: 30px;

      span {
        width: 50px;
        height: 15px;
      }
    }
  }

  .B_r {
    .banner04 {
      padding: 10px;
      padding-bottom: 0;

      width: 100%;
      height: 100px;
      box-sizing: border-box;
    }
    width: 230px;
    box-sizing: border-box;
    background: #fff;
    .check {
      background: #fff;
      .sin-img {
        height: 150px;
        text-align: center;

        padding: 10px;
        .sin-h {
          p {
            height: 40px;
          }
        }
        img {
          width: 50px;
          height: 50px;
          border: 1px solid #ccc;
          border-radius: 50%;
        }
        .el-button {
          height: 30px;
          line-height: 30px;
          padding: 0;
          width: 70px;
        }
      }
      .sin-list {
        li {
          line-height: 30px;
          width: 50%;
          text-align: center;
          display: inline-block;

          i {
            margin-top: 5px;
            font-size: 18px;
            margin-right: 5px;
            color: #fe6925;
          }
        }

        .sin {
          text-align: left;
          width: 229px;
          // margin: 0 15px;
          // border-bottom: 1px solid #ccc;
          position: relative;
          background: #fff;
          .sin-top {
            padding: 20px 10px;
          }
          @include space-between;

          .sin-h {
            flex: 1;

            h1 {
              padding: 10px;
            }
          }
        }

        .sin-login {
          .el-button {
            width: 160px;
            text-align: center;
            padding: 10px;
            margin-bottom: 20px;
          }
          p {
            text-align: center;
          }

          p span:nth-child(1) {
            a {
              color: #fff;

              height: 30px;
              line-height: 30px;
              box-sizing: border-box;
              background: #e94c15;
              cursor: pointer;
              width: 80px;
              text-align: center;
              display: inline-block;
              vertical-align: middle;
            }
          }

          p span:nth-child(2) {
            a {
              width: 80px;
              text-align: center;
              display: inline-block;
              border: 1px solid #e94c15;
              color: #e94c15;
              height: 30px;
              line-height: 30px;
              box-sizing: border-box;
              cursor: pointer;
              vertical-align: middle;
              margin-left: 10px;
            }
          }
        }

        .sin-to {
          p {
            text-align: center;
            color: #e94c15;
            cursor: pointer;
          }
        }
      }
    }

    height: 460px;
    width: 230px;
    float: right;

    // margin:0 0 0 100px;
    .notice {
      padding: 0 20px;

      color: #222;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      font-weight: 800;
      margin: 0;
      background: #fff;
      border-bottom: 1px solid #ccc;

      // border-top: 1px solid #ccc;
      // padding: 0px 15px 0 15px;
      span {
        text-align: center;
        // margin-right: 20px;
        cursor: pointer;
        display: block;
        float: left;
        height: 39px;
        // padding: 0 15px;
      }

      .cur {
        // margin-right: 20px;
        cursor: pointer;
        display: block;
        float: left;
        height: 39px;
        padding: 0 15px;
        border-bottom: 2px solid #e94c15;
        color: #e94c15;
      }
    }
  }
}

.List-title {
  text-align: center;

  h1 {
    height: 35px;
    line-height: 35px;
    font-size: 16px;
    width: 235px;
    background: #f15453;
    color: #fff;
    padding-left: 5px;
  }
}

.List {
  padding: 10px;
  width: 100%;

  .title {
    font-size: 22px;
    padding: 0 20px;
    margin-bottom: 10px;
    padding: 20px;
  }
  background: #fff;
  overflow: hidden;

  .product-list {
    display: flex;
    align-content: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    .item:hover {
      border: 1px solid #eee;
    }
    .item {
      padding: 10px;

      overflow: hidden;

      background: #fff;
      position: relative;

      box-sizing: border-box;
      height: 280px;
      width: 230px;

      box-sizing: border-box;

      a {
        @include space-between;
      }
      .item-img-warper {
        width: 100%;

        height: 200px;
        box-sizing: border-box;

        .item-img {
          box-sizing: border-box;
          width: 100%;

          height: 100%;
        }
      }
      .item-info {
        @include wrap-between;

        width: 100%;
        .item-name {
          text-align: left;
          padding: 5px 0;
          font-size: 14px;
          color: #757575;
          line-height: 18px;
          overflow: hidden;
          height: 43px;
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .item-price {
          width: 100%;
          line-height: 20px;
          text-align: left;
          span {
            color: red;
            font-size: 18px;
            text-align: left;
          }
        }
      }
    }
  }
}

.Right {
  position: fixed;
  bottom: 300px;
  left: 50%;
  margin-left: -710px;
  z-index: 9999;
  width: 35px;
  text-align: center;
  cursor: pointer;

  li {
    border: 1px solid #ccc;
    font-size: 12px;
    width: 80px;
    height: 50px;
    line-height: 50px;
    text-align: center;

    :hover {
      background-color: #c81623;
      color: #fff;
    }
  }

  .cur {
    background-color: #f15453;
    color: #fff;
  }
}
</style>
//全局样式
 