<template>
  <div class="notice_list_home">
    <div class="mine">
      <ul>
        <li
          v-for="not in noticData"
          :key="not.id"
        >
             <p @click="to(not.notice_id)"><i
                class="el-icon-zoom-in"
                style="margin-right: 5px;"
              ></i>{{not.notice_title}}</p>
         </li>
      </ul>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      noticData:[]
    };
  },
  methods: {
    to(id) {
      let routerJump = this.$router.resolve({
        path: "/information",
        query: {
          id: id
        }
      });
      window.open(routerJump.href, "_blank");
    }
  },
  components: {},
  created() {},
  mounted() {
    this.$get("/home/noticetwo").then(res => {
      this.noticData = res.items.data;
    });
  }
};
</script>
<style lang="scss" scoped>
.mine {
  width: 100%;
  height:108px;
  background: #fff;
  padding: 10px;
  ul {
    li {
      
      display: block;
      font-size: 12px;
      height: 22px;
      line-height: 22px;
    
      
      cursor: pointer;
      p {
          overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
        color: #222;
        a {
          i {
            margin-right: 5px;
            color: #e94c15;
          }
        }
      }
      p:hover {
        color: #e94c15;
      }
    }
  }
  ul li:hover {
    border-bottom: 1px solid #ccc;
  }
  ul li:nth-child(1) {
    margin-top: 7px;
  }
}
</style>
